import ApiHelper from './index';
// import { getAuthConfig } from './config';

const getDeviceCameraConfig = async (spinner = true, queryParams = {}) => {
  return await ApiHelper.get(
    'device/get_devices_with_camera_config',
    spinner,
    queryParams
  );
};

const getAvailableCameras = async (
  deviceId,
  spinner = true,
  queryParams = {}
) => {
  return await ApiHelper.get(
    `device/available_cameras/${deviceId}`,
    spinner,
    queryParams
  );
};

const getDeviceAvailableCameras = async (
  serialNumber,
  spinner = true,
  queryParams = {}
) => {
  return await ApiHelper.get(
    `device/get_device_available_cameras/${serialNumber}`,
    spinner,
    queryParams
  );
};

export default {
  getDeviceCameraConfig,
  getAvailableCameras,
  getDeviceAvailableCameras
};
